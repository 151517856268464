import { Link } from "gatsby"
import React from "react"

const Footer = () => {
  return (
    <div className="max-w-7xl mx-auto p-20 mt-10 bg-lightblack rounded-xl">
      <div className="grid grid-cols-2 xxs:grid-cols-1 lg:grid-cols-2">
        <div>
          <h1 className="text-white text-4xl text-bold">Fundationary</h1>
          <p className="text-white text-sm mt-5 opacity-50">
            Fundationary is a commercial organisation dedicated to helping community clubs raise funds via the traditional method of domino card games.
            Fees are charged to the club for the organisation and management of the game and to cover the payment processing costs.
          </p>
          <h1 className="text-white text-lg mt-20 ">
            © copyright{" "}
            <a href="https://www.fundationary.com/">Fundationary</a>
          </h1>
        </div>
        <div className="place-self-end self-center xxs:hidden lg:block">
          <ul className="text-xl font-poppins font-bold">
            <li>
              <Link to="/">Success Stories</Link>
            </li>
            <li>
              <Link to="/">Products</Link>
            </li>
            <li>
              <Link to="/">Company</Link>
            </li>{" "}
            <li>
              <Link to="/">Clubs</Link>
            </li>
            <li>
              <Link to="/">Blog</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="mt-10 text-left text-sm text-white opacity-50">
        <Link to="/terms-and-conditions" className="mr-4">Terms and Conditions</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
    </div>
  )
}

export default Footer
